import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import VideoHero from "../components/videobackground/videobackground"
import ButtonDivWidget from "../components/buttonDivWidget/buttonDivWidget"

// Basing website on this design: https://www.iqlance.com/#

const CloudSolutionsPage = () => (
  <Layout>
    <Seo title="Home" />
    <VideoHero />
    <h1>Details TBD</h1>
    <p>More to come</p>
    <ButtonDivWidget cta="Ready to get started?" text="Get a Quote Today" />
  </Layout>
)

export default CloudSolutionsPage
